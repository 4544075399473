/* General body styling */
body {
  font-family: 'Poppins', Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
  color: #333;
  text-align: center;
}

/* Header styling */
h1 {
  font-size: 2.8rem;
  color: #4CAF50; /* Green from the logo */
  margin-top: 20px;
}

p {
  font-size: 1.2rem;
  color: #34495e; /* Dark blue/grey for text */
}

/* Logo styling */
img {
  margin-top: 20px;
  width: 270px; /* 50% larger than 180px */
  height: auto;
}

/* Form styling */
form {
  margin: 30px auto;
}

input {
  padding: 10px;
  width: 250px;
  font-size: 16px;
  border: 2px solid #3498DB; /* Blue border */
  border-radius: 5px;
  margin-bottom: 10px;
  outline: none;
  transition: border-color 0.3s;
}

input:focus {
  border-color: #4CAF50; /* Green border on focus */
}

button {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #4CAF50; /* Green background */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #3e8e41; /* Darker green on hover */
}

/* Prediction styling */
.prediction {
  margin-top: 20px;
  font-size: 1rem;
  color: #3498DB; /* Blue for prediction text */
}

/* Footer styling */
footer {
  margin-top: 40px;
  font-size: 0.9rem;
  background-color: #f5f5f5; /* Makes the background color transparent */
  color: #f5f5f5; /* Ensures text matches the surrounding content */
  border: none; /* Removes any borders if present */
}

/* Style for the main content container */
.content-container {
  width: 70%; /* Set the width to 70% of the page */
  margin: 0 auto; /* Center the content horizontally */
  text-align: center; /* Optional: Center-align the text */
}

/* In your CSS file or within a <style> tag */
.spinner {
  margin: 16px auto;
  width: 40px;
  height: 40px;
  border: 4px solid #ddd;
  border-top-color: #1e90ff;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}